<template>
  <div class="login_is" :style="shadow">
    <div class="form_content relative">
      <ul class="tab">
        <li @click="active = 0" :class="active == 0?'active':''">短信登录</li>
        <li @click="active = 1" :class="active == 1?'active':''">密码登录</li>
        <li v-if="type == 2" @click="goRouter('/companyLogin/retrieve')">找回账号</li> <!-- -->
      </ul>
      <div class="qr" v-if="false">
        <div class="text">微信扫一扫登录</div>
        <img src="~/static/login/qr.png" alt="">
      </div>
      <el-form :model="form" :rules="error" ref="form" class="form"  @submit.native.prevent  v-show="active == 0">
        <el-form-item prop="iphone">
          <el-input v-model.trim="form.iphone" @change="getFindIphone(1,form.iphone)" class="wrap_input"
                    placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item prop="iphoneCode">
          <el-input
              class="wrap_input"
              v-model="form.iphoneCode"
              placeholder="请输入短信验证码"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
          >
            <template slot="append">
              <el-button
                  @click="sendCheckCode"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
              >{{ btntxt }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="button">
          <el-button :type="form.iphone?'primary':'info'" :disabled="form.iphone?false:true" class="btn"
                      native-type="submit"   @click="getLogin('form','1')" :loading="loading">登录/注册
          </el-button>
        </el-form-item>
        <el-form-item prop="checkbox">
          <el-checkbox v-model="form.checkbox" class="checked">
            我接受
            <router-link to="/protocol/userServe/" target="_blank">用户服务协议</router-link>
            和
            <router-link to="/protocol/agreement/" target="_blank">隐私政策</router-link>
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <p style="margin-top:-30px">未注册手机号验证后将自动注册本平台账号</p>
        </el-form-item>
      </el-form>
      <el-form :model="forms" :rules="errors" ref="forms" class="form forms" @submit.native.prevent  v-show="active == 1">
        <el-form-item prop="userName">
          <el-input v-model.trim="forms.userName" @change="getFindIphone(2,forms.userName)" class="wrap_input"
                    placeholder="请输入用户名/手机号"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
              class="wrap_input"
              v-model.trim="forms.pass"
              type="input"
              show-password
              placeholder="请输入密码"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
          >
            <template slot="append">
              <div class="pass" @click="goRouter('/retrievePassword','',status)">忘记密码？</div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <ImageCode
              @temp="gettemp"
              @changeCode="getchangeCode"
              ref="myImageCode"
          ></ImageCode>
        </el-form-item>
        <el-form-item class="button">
          <el-button :type="forms.userName?'primary':'info'" class="btn" :disabled="forms.userName?false:true"
                     @click="getLogin('forms','0')"  native-type="submit" :loading="loadings">
            登录
          </el-button>
        </el-form-item>
        <el-form-item prop="checkbox">
          <el-checkbox v-model="forms.checkbox" class="checked">
            我接受
            <router-link to="/protocol/userServe/" target="_blank">用户服务协议</router-link>
            和
            <router-link to="/protocol/agreement/" target="_blank">隐私政策</router-link>
          </el-checkbox>
        </el-form-item>
        <el-form-item v-show="false">
          <p style="margin-top:-30px">未注册手机号验证后将自动注册本平台账号</p>
        </el-form-item>
      </el-form>
      <div class="more">
        选择其它登录方式
      </div>
      <ul class="login_more" :class="active == 1?'pass_more':''">
        <!-- <li>
          <a aria-label="微信登录" @click="openLogin('https://open.weixin.qq.com/connect/qrconnect?appid=wx1585f89dc56003a4&redirect_uri='+encodeURI('https://www.cdpee.org.cn/api/app-jycy-member/PcWeixinLogin')+'&response_type=code&scope=snsapi_login&state=7,'+openUrl+'/Login/,'+userInfo.token,7)"
             href="javascript:void(0);" class="wechat"></a>
        </li> -->
        <li>
          <a aria-label="微信登录" @click="goRouter('/weixinLogin')"
             href="javascript:void(0);" class="wechat"></a>
        </li>
        <li>
          <a aria-label="支付宝登录"
             @click="openLogin('https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002191647409&scope=auth_base&redirect_uri='+encodeURIComponent('https://www.cdpee.org.cn/api/app-jycy-member/zhiBaoLogin')+'&state=5,'+openUrl+'/Login/,'+userInfo.token,5)"
             href="javascript:void(0);" class="pay"></a>
        </li>
        <li>
          <a aria-label="QQ登录"
             @click="openLogin('https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=101981890&redirect_uri='+encodeURIComponent('https://www.cdpee.org.cn/api/app-jycy-member/getTokenByCode')+'&state='+openUrl+'/Login/,'+userInfo.token,9)"
             href="javascript:void(0);" class="qq"></a>
        </li>
      </ul>
    </div>
    <el-dialog
        :visible.sync="isHide"
        width="300px"
        :show-close="false"
        :before-close="handleClose">
      <div class="alert">
        <img src="~/static/login/tip.png" alt="">
        <h3>您当前短信登录的是求职者账号 <br/>
          如想招聘员工请进入企业版
        </h3>
        <div class="btn flex">
          <el-button type="primary" plain @click="goRouter('/user/companyLogin')">前往企业版</el-button>
          <el-button type="primary" @click="sendMsg">继续</el-button>
        </div>
        <el-checkbox v-model="dialogCheckbox" class="checked" @change="handChange">
          我已了解，不再提示
        </el-checkbox>
      </div>
    </el-dialog>
    <dialogImageCode ref="dialogImageCode" @changeCode="sendMsg"/>
  </div>
</template>

<script>
import ImageCode from "./ImageCode";
import {mapState, mapMutations} from "vuex";
import dialogImageCode from "components/login/dialogImageCode";
export default {
  name: "loginAll",
  inject: ["reload"],
  props: ["shadow", "status", "type"],
  components: {
    ImageCode,
    dialogImageCode
  },
  computed: {
    ...mapState(["userPassword"])
  },
  data() {
    return {
      active: 1,
      loading: false,
      loadings: false,
      actives: '',
      btntxt: "获取验证码",
      time: 180,
      getCodeBtnDisable: false,
      isHide: false,
      dialogCheckbox: '',
      userInfo: {},
      form: {
        iphone: '',
        iphoneCode: '',
        checkbox: false,
      },
      error: {
        iphone: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        iphoneCode: [
          {required: true, message: "请输入收到的验证码", trigger: "blur"},
        ],
        checkbox: [
          {required: true, message: "请先阅读并勾选用户服务协议和隐私政策", trigger: "change"},
        ],
      },
      forms: {
        userName: '',
        pass: '',
        checkbox: false,
        temp: '',
        code: '',
        verifycode: '',
      },
      errors: {
        userName: [
          {required: true, message: "请输入用户名/手机号", trigger: "blur"},
        ],
        pass: [
          {required: true, message: "请输入密码", trigger: "blur"},
        ],
        checkbox: [
          {required: true, message: "请先阅读并勾选用户服务协议和隐私政策", trigger: "change"},
        ],
      },
      openUrl: 'https://www.cdpee.org.cn',
      login: {
        thirdId: '',
        thirdType: '',
      }
    }
  },
  methods: {
    //
    ...mapMutations(['setPassword']),
    //手机号密码登录
    getLogin(form, type) {
      let that = this;
      let param = {};
      if (type == 0) {
        if (!this.forms.checkbox) {
          this.$message.error("请先阅读并勾选用户服务协议和隐私政策");
          return;
        }
        param = {
          userName: this.forms.userName,
          password: this.$sm2Encrypt(this.forms.pass),
          code: this.forms.code,
          uuid: this.forms.verifycode,
          loginType: type,
          registerSite:'lz',
          touristType: this.type,
          thirdId: this.login.thirdId,
          thirdType: this.login.thirdType
        }
      } else {
        if (!this.form.checkbox) {
          this.$message.error("请先阅读并勾选用户服务协议和隐私政策");
          return;
        }
        param = {
          userName: this.form.iphone,
          password: this.form.iphoneCode,
          loginType: type,
          registerSite:'lz',
          touristType: this.type,
          thirdId: this.login.thirdId,
          thirdType: this.login.thirdType
        }
      }
      this.setPassword(param.password);
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.$refs.myImageCode.getCaptcha();
          let res = await this.$api.getLoginApi(param);
          if (res.data.success) {
            this.loading = false;
            let user = res.data.data;
            that.$message.success('登录成功！');
            //残疾人个人信息
            that.$store.commit("SET_USER_INFO", user);
            //存储access_token
            that.$store.commit("SET_TOKEN", user.token);
            //存储refresh_token
            that.$store.commit("SET_REFRESH_TOKEN", user.token);
            if (user.userType === "1") {
              this.$router.push("/center");
              this.reload();
              let red = await this.$api.wangTiTrainApplyTips();
              if (red.data.success == false) {
                if (red.data.msg != "") {
                  this.$message.error(red.data.msg)
                }
              }
            } else if (user.userType === "2") {
              if (user.memberStatus == "0") {
                that.$router.push("/enterprise/centre/infomanage/");
              }
              this.$router.push("/enterprise");
              this.reload();
              this.loading = false;
            } else {
              this.reload();
              let red = await this.$api.wangTiTrainApplyTips();
              if (red.data.success == false) {
                if (red.data.msg != "") {
                  this.$message.error(red.data.msg)
                }
              }
              if (user.touristType == 2) {
                this.$router.push("/companyLogin/authentication");
              } else {
                this.$router.push("/userLogin/authentication");
              }
            }
          } else {
            this.loading = false;
            if (res.data.msg) {
              this.$message.error(res.data.msg)
            }
          }
        } else {
          this.loading = false;
        }
      })
    },
    sendCheckCode() {
      let iphone = /^1[3456789]\d{9}$/;
      let codeAlert = localStorage.getItem('codeAlert');
      if (iphone.test(this.form.iphone)) {
        if (this.$route.query.id == 'geren') {
          if (codeAlert) {
            this.$refs.dialogImageCode.open();
          } else {
            this.isHide = true;
            return
          }
        } else {
          this.$refs.dialogImageCode.open();
        }
      } else {
        this.$message.error("手机号码不正确");
      }
    },
    // 单独领出来的短信发送接口
    sendMsg(val) {
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post(
              "/api/app-jycy-phoneverificationcode/verification",
              {
                phone:this.form.iphone,
                uuid:val.uuid,
                code:val.code
              }
          ).then(res => {
        if (res.data.success) {
          this.$message.success({
            message: "验证码已经发送至您的手机，请注意接收",
          });
          this.time = 180;
          this.timer();
        } else {
          this.getCodeBtnDisable = false;
          this.btntxt = '获取验证码';
          this.$message.error({
            message: res.data.msg || "发送失败",
          });
        }
      })
      this.$refs.dialogImageCode.close();
      this.handleClose();
    },
    isSend(){
      this.$refs.dialogImageCode.open();
      this.isHide = false;
    },
    //验证手机号码是否注册
    getFindIphone(type, username) {
      this.$api.getfindIphoneApi({iphone: this.$sm2Encrypt(username)}).then(res => {
        if (res.data.code == 8112) {
          if (type == 1) {
            this.form.checkbox = true;
          } else {
            this.forms.checkbox = true;
          }
        } else {
          if (type == 1) {
            this.form.checkbox = false;
          } else {
            this.forms.checkbox = false
          }
          // this.$message.error({
          //   message: res.data.msg,
          // });
        }
      })
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.getCodeBtnDisable = false;
      }
    },
    handChange(val) {
      if (val) {
        localStorage.setItem('codeAlert', val);
      } else {
        localStorage.removeItem('codeAlert');
      }
    },
    handleClose() {
      this.isHide = false;
    },
    goRouter(url, id, active) {
      const {href} = this.$router.resolve({
        path: url,
        id: id,
        active: active
      });
      window.location.href = href;
    },
    getchangeCode(val) {
      console.log(val)
      this.forms.verifycode = val.uuid;
      this.forms.code = val.code;
    },
    gettemp(val) {
      this.forms.temp = val;
    },
    //检查是否绑定账号
    getInfo() {
      if (this.$route.query.userInfo) {
        this.userInfo = JSON.parse(this.$route.query.userInfo);
        //残疾人个人信息
        this.$store.commit("SET_USER_INFO", this.userInfo);
        //存储access_token
        this.$store.commit("SET_TOKEN", this.userInfo.token);
        //存储refresh_token
        this.$store.commit("SET_REFRESH_TOKEN", this.userInfo.token);
        if (this.userInfo.userType == 1) {
          this.$router.push('/center');
        } else if (this.userInfo.userType == 2) {
          this.$router.push('/enterprise');
        } else if (this.userInfo.userType == 4) {
          if (this.userInfo.touristType == 2) {
            this.$router.push("/companyLogin/authentication");
          } else {
            this.$router.push("/userLogin/authentication");
          }
        }
      }
      if (this.$route.query.error) this.$message.error('请登录账号进行绑定！');
      if (this.$route.query.loginType) this.login.thirdType = this.$route.query.loginType;
    },
    openLogin(url, type) {
      window.location.href = url;
      this.login.thirdType = type;
    },
    //qq登录
    qqLogin() {

    },
  },
  created() {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
    this.login.thirdId = this.$route.query.openId;
    this.active = this.$route.query.active || 1;
    // this.qqLogin();
  },
  mounted() {
    this.getInfo();
  }
}
</script>

<style scoped lang="less">
.login_is {
  width: 408px;
  height: 498px;
  background: white;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  .form_content {
    padding: 0 36px;

    .tab {
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-top: 12px;

      li {
        padding: 19px 0;
        text-align: center;
        font-size: 16px;
        color: #66716C;
        margin-right: 24px;
        cursor: pointer;
      }

      li.active {
        position: relative;
        font-size: 18px;
        font-weight: bold;
        color: #00120A;
      }

      li.active::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #28A46D;
      }
    }

    .qr {
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;

      .text {
        width: 102px;
        background: url("~static/login/border.png") center top no-repeat;
        font-size: 12px;
        color: #FF9600;
        text-align: center;
      }

      img {
        cursor: pointer;
      }
    }

    /deep/ .form {
      padding: 24px 0;
      margin-top: 6px;

      .el-form-item {
        margin-bottom: 24px;

        .el-input__inner::placeholder {
          font-size: 14px;
          color: #99A09D;
        }

        .el-input-group--append {
          border: 1px solid #CECFD0;
          border-radius: 6px;

          .el-input__inner {
            border: none;
          }
        }

        .el-input-group__append {
          border: none;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.03);

          span {
            display: inline-block;
            font-size: 14px;
            color: #66716C;
            padding-left: 5px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .el-button.is-disabled, .el-button.is-disabled:hover, .el-button.is-disabled:focus {
            border: none;
            background: none;
            color: #28A46D;
          }

          .pass {
            color: #FF9600;
            cursor: pointer;
          }
        }

        .btn {
          width: 100%;
          font-size: 14px;
          border: none;
        }

        .el-button--info {
          color: #99A09D;
          background: rgba(0, 0, 0, 0.05);
        }

        .checked {
          color: #66716C;

          a {
            color: #00924b;
          }
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #66716C;
        }

        .imageChecking {
          .el-input {
            .el-input__inner {
              background: rgba(0, 0, 0, 0.03);
            }

            .el-input-group__append {
              img {
                margin-top: 5px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .button {
        margin-bottom: 0;
      }
    }

    .more {
      width: 126px;
      height: 30px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #66716C;
      transform: translateY(-10px);
    }

    .login_more {
      display: flex;
      width: 66%;
      margin: 20px auto 0 auto;

      li {
        flex: 1;
        text-align: center;

        a {
          display: inline-block;
          width: 42px;
          height: 42px;
        }

        .wechat {
          background: url("~static/login/wechat.png") center top no-repeat;
          background-size: 100% 100%;
        }

        .pay {
          background: url("~static/login/pay.png") center top no-repeat;
          background-size: 100% 100%;
        }

        .qq {
          background: url("~static/login/qq.png") center top no-repeat;
          background-size: 100% 100%;
        }

        .wechat:hover {
          background: url("~static/login/wechat_color.png") center top no-repeat;
        }

        .pay:hover {
          background: url("~static/login/pay_color.png") center top no-repeat;
        }

        .qq:hover {
          background: url("~static/login/qq_color.png") center top no-repeat;
        }
      }
    }

    .pass_more {
      margin-top: 0;
    }

    .forms {
      padding-bottom: 0;
    }
  }

  /deep/ .el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }

    .alert {
      text-align: center;
      border-radius: 6px;

      img {
        margin-bottom: 12px;
      }

      h3 {
        font-size: 16px;
      }

      .btn {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 18px;

        .el-button {
          width: 102px;
          text-align: center;
        }
      }
    }
  }
}
</style>
